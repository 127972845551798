import React, {useEffect} from 'react';
import Layout from "../components/Layout";
import PageHeader from "../components/PageHeader";
import Footer from "../components/Footer";
import NavThree from "../components/NavThree";
import Projects from "../components/Projects";
import CourseCatThree from "../components/CourseCatThree";
import CallToActionFive from "../components/CallToActionFive";
import CallToActionFour from "../components/CallToActionFour";

const Page404 = () => {
    useEffect(() => {

        window.location.replace("https://www.youtube.com/channel/UCAZgvndcorh9hqGg8vg_8Rw?sub_confirmation=1")
    }, []);

    return (
        <Layout pageTitle="Youtube | Not Found">

        </Layout>
    );
};

export default Page404;
